import React, { useCallback, useEffect, useState } from 'react';
import { LmButton, LmInfoBox } from '@mes-ui/lemma';
import Popup from '../../../../../../components/modals/popup';
import { numberBirthRecordRegExp } from '../../../../../../lib/utils/validation';
import { DocumentTypeEnum } from '../../../../../../mock-data/type-document';
import { PersonRequestData } from '../../../../../../types/requests';
import { emptyLikeError, sortList } from '../../../../utils';
import { OtherPerson } from './other-person';
import { Error } from './error';
import { MatchTypeEnum } from '../../../../../../mock-data/like-modal-enum';
import { useDegree } from '../../../../../../lib/utils/like-modal';
import { ErrorPerson, ListPerson, Person as PersonType } from '../../../../../../types/like-modal';
import Person from './person';
import { redirect } from '../../../../../../lib/utils';
import { useGetAppealLinkQuery } from '../../../../../../store/home';
import { LoaderCustom } from '../../../../../../components/loader-custom';

type Props = {
  title: string;
  open: boolean;
  updateRequest: () => void;
  close: (value: boolean) => void;
  requestId: number | undefined;
  data?: PersonRequestData;
  getList: () => Promise<ListPerson[] | undefined>;
  createNewPerson: () => Promise<{ message: string } | string | undefined>;
  isShortBirthRecordCheck?: boolean;
  onSelectPerson: (checked: PersonType) => Promise<void>;
};

const LikeModal = ({
  title,
  updateRequest,
  open,
  close,
  data,
  requestId,
  getList,
  createNewPerson,
  isShortBirthRecordCheck,
  onSelectPerson,
}: Props) => {
  const [listPerson, setListPerson] = useState<ListPerson[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [selectLoadingBtn, setSelectLoadingButton] = useState<boolean>(false);
  const [checked, setChecked] = useState<PersonType | undefined>();
  const [loadingNewPersonBtn, setLoadingNewPersonBtn] = useState<boolean>(false);
  const [showSTP, setShowSTP] = useState(false);

  const matchType = useDegree(listPerson);
  const showButtonSTP = matchType === MatchTypeEnum.oneMedium || matchType === MatchTypeEnum.severalWithHigh || showSTP;

  const { data: appealLink = '' } = useGetAppealLinkQuery(undefined, {
    skip: !showButtonSTP,
  });

  const [error, setError] = useState<ErrorPerson>(emptyLikeError);

  const isShortBirthRecord =
    isShortBirthRecordCheck &&
    !!data &&
    data.documentTypeId === DocumentTypeEnum.BirthRecord &&
    !!data.number &&
    !numberBirthRecordRegExp.test(data.number.toString());

  const getListPerson = useCallback(async (): Promise<ListPerson[] | undefined> => {
    try {
      setLoading(true);
      const response = await getList();

      response && setListPerson(sortList(response));

      return response;
    } finally {
      setLoading(false);
    }
  }, [getList]);

  useEffect(() => {
    open && getListPerson();
  }, [getListPerson, open]);

  const newPerson = async () => {
    if (requestId) {
      setLoadingNewPersonBtn(true);
      const response = await createNewPerson();

      if (typeof response === 'object' && response.message) {
        setError({
          text: response.message,
          color: 'danger',
          show: true,
        });
        setShowSTP(true);
      } else if (typeof response === 'string') {
        const listPerson = await getListPerson();

        if (listPerson?.length) {
          setError({
            text: response,
            color: 'success',
            show: true,
          });
          setShowSTP(false);
        }
      }

      setLoadingNewPersonBtn(false);
    }
  };

  const selectPerson = async () => {
    if (checked) {
      setSelectLoadingButton(true);

      try {
        await onSelectPerson(checked);
        updateRequest();
        close(false);
        setSelectLoadingButton(false);
      } catch {
        setSelectLoadingButton(false);
      }
    }
  };

  const sendSTP = () => {
    redirect(appealLink);
    close(false);
  };

  const handleClearData = useCallback(() => {
    setListPerson([]);
    setLoading(false);
    setChecked(undefined);
    setShowSTP(false);
    setError(emptyLikeError);
  }, []);

  return (
    <Popup
      dataTest="likePopup"
      open={open}
      title={title}
      size="large"
      onClose={close}
      onClearData={handleClearData}
      hidePrimaryButton
      hideSecondaryButton
      additionalButtons={
        <>
          <LmButton
            type="button"
            variant="secondary"
            onClick={() => close(false)}
          >
            Отмена
          </LmButton>

          {!loading ? (
            <>
              {showButtonSTP && (
                <LmButton
                  type="button"
                  variant="outline"
                  onClick={sendSTP}
                >
                  Обратиться в СТП
                </LmButton>
              )}
              {(matchType === MatchTypeEnum.oneLow ||
                matchType === MatchTypeEnum.several ||
                matchType === MatchTypeEnum.noMatch) && (
                <LmButton
                  type="button"
                  variant={matchType !== MatchTypeEnum.noMatch ? 'outline' : 'primary'}
                  loading={loadingNewPersonBtn}
                  disabled={isShortBirthRecord}
                  onClick={newPerson}
                >
                  Создать новую персону
                </LmButton>
              )}
              {matchType !== MatchTypeEnum.noMatch && (
                <LmButton
                  type="button"
                  disabled={!checked}
                  loading={selectLoadingBtn}
                  onClick={selectPerson}
                >
                  Выбрать
                </LmButton>
              )}
            </>
          ) : null}
        </>
      }
    >
      <div className="requests-check-details">
        <div className="requests-check-details__item">
          <Person data={data} />

          <div className="requests-check-details__inner line-height-text">
            <Error {...error} />

            {!loading && !error.text
              ? matchType === MatchTypeEnum.oneLow
                ? 'По указанным Вами данным найдена похожая персона:'
                : matchType === MatchTypeEnum.oneMedium
                  ? 'Указанные Вами данные частично совпадают с данными обучающегося в Реестре контингента. Вы можете использовать данные найденной персоны, нажав на кнопку “Выбрать”.'
                  : matchType === MatchTypeEnum.oneHigh
                    ? 'Данные обучающегося успешно найдены в Реестре контингента:'
                    : matchType === MatchTypeEnum.several
                      ? 'По указанным Вами данным найдено несколько похожих персон:'
                      : matchType === MatchTypeEnum.severalWithHigh
                        ? 'По указанным Вами данным найдено соответствие с несколькими персонами:'
                        : !isShortBirthRecord &&
                  'По указанным Вами данным похожие персоны не найдены. Для создания новой персоны нажмите кнопку "Создать новую персону”.'
              : null}
          </div>
        </div>
        {!loading ? (
          listPerson.length ? (
            listPerson.map(({ person, degree }: ListPerson) => {
              return (
                <OtherPerson
                  key={person.id}
                  checked={checked}
                  person={person}
                  degree={degree}
                  setChecked={setChecked}
                  documentTypeId={data?.documentTypeId}
                  documentTypeName={data?.documentTypeName}
                />
              );
            })
          ) : (
            isShortBirthRecord && (
              <div className="container">
                <LmInfoBox
                  dataTest="emptyListAndShortNumberAlert"
                  variant="alert"
                  className="infobox--full-width"
                  description="По указанным Вами данным похожие персоны не найдены. Выполните отказ в зачислении и сообщите заявителю о необходимости подачи заявления по свидетельству о рождении или с 21-разрядным номером записи о рождении в ЕГР ЗАГС"
                  hidenFooter
                />
              </div>
            )
          )
        ) : (
          <LoaderCustom size={40} />
        )}

        {!loading
          ? (matchType === MatchTypeEnum.oneLow ||
              matchType === MatchTypeEnum.oneMedium ||
              matchType === MatchTypeEnum.several ||
              matchType === MatchTypeEnum.severalWithHigh) && (
              <div className="requests-check-details__item">
                <div className="requests-check-details__inner line-height-text">
                  {matchType === MatchTypeEnum.oneLow
                    ? 'Для использования найденной персоны нажмите кнопку “Выбрать”. Для создания новой персоны нажмите кнопку “Создать новую персону”.'
                    : matchType === MatchTypeEnum.oneMedium
                      ? 'Если Вы уверены, что найденные данные содержат ошибку, то обратитесь в СТП. Создать нового обучающегося с указанными данными невозможно.'
                      : matchType === MatchTypeEnum.several
                        ? 'Выберите одну из найденных персон и нажмите кнопку “Выбрать”.  Для создания новой персоны нажмите кнопку “Создать новую персону”.'
                        : matchType === MatchTypeEnum.severalWithHigh
                          ? 'Если Вы уверены, что найденные данные содержат ошибку, то обратитесь в СТП. Создать нового обучающегося с указанными данными невозможно.'
                          : ''}
                </div>
              </div>
            )
          : null}
      </div>
    </Popup>
  );
};

export default LikeModal;
