import React, { useCallback, useState } from 'react';
import { object as objectYup } from 'yup';
import { Formik, FormikProps } from 'formik';
import { Push } from '@mosru/esz_uikit';
import { LmButton, LmLoader } from '@mes-ui/lemma';
import FormikDatePicker from '../../../components/formik/formik-datepicker';
import FormikInput from '../../../components/formik/formik-input';
import Document from '../components/fields/document';
import { FormTypeEnum } from '../../../mock-data/form-type-enum';
import SimpleTextArea from '../../../components/fields/simple-textarea';
import ServiceClassesEnrollment from '../components/fields/service-classes-enrollment';
import { PersonRequestData, RequestEnrollmentData } from '../../../types/requests';
import useInitialErrors from '../../../hooks/formik-initial-errors';
import ShortNumberInfobox from '../components/short-number-infobox';
import { submitEnrollment } from '../utils';
import { EnrollmentModal } from '../components/modals';
import { docDate, documentTypeId, enrollDocNumber, serviceClassId } from '../../../lib/utils/validation';
import { RequestStatusEnum } from '../../../mock-data/request-status-enum';

type Props = {
  enrollment: RequestEnrollmentData;
  serviceId: number;
  requestId: number;
  updateRequest: () => void;
  contingentGuid: string;
  child: PersonRequestData;
  requestStatusId: RequestStatusEnum;
};

const ActionsFormEnrollment: React.FC<Props> = ({
  contingentGuid,
  enrollment,
  serviceId,
  requestId,
  updateRequest,
  child,
  requestStatusId,
}) => {
  const [showModal, setShowModal] = useState(false);

  const [loading, setLoading] = useState(false);

  const submitForm = useCallback(
    async (values: RequestEnrollmentData) => {
      await submitEnrollment(values, requestId, setLoading, setShowModal, undefined, updateRequest);
    },
    [requestId, updateRequest]
  );
  const initialErrors = useInitialErrors(enrollment, getValidationSchema());

  const updatedInitialValues = {
    ...enrollment,
    enrollDocumentTypeId: FormTypeEnum.EditRequestEnrollment,
  };

  return loading ? (
    <div className="loader-container">
      <LmLoader
        view="page"
        title="Идет сохранение! "
        description="Пожалуйста, подождите..."
      />
    </div>
  ) : (
    <Formik
      validationSchema={getValidationSchema()}
      onSubmit={submitForm}
      enableReinitialize
      initialValues={updatedInitialValues}
      initialErrors={initialErrors}
    >
      {(formikProps: FormikProps<RequestEnrollmentData>) => {
        const { handleSubmit, isSubmitting, isValid, resetForm } = formikProps;

        return (
          <form onSubmit={handleSubmit}>
            <ShortNumberInfobox
              requestStatusId={requestStatusId}
              child={child}
            />

            <ServiceClassesEnrollment
              serviceId={serviceId}
              contingentGuid={contingentGuid}
              warningMessage="Группы обучения для указанной услуги не найдены. Для зачисления доступны только те группы обучения, в которых выбрана указанная услуга."
              type="EditVA"
            />
            <Push size={16} />
            <div className="container">
              <div className="table-data">
                <div className="table-data__item table-data__group">
                  <div className="table-data__label table-data__label--main">
                    Дата зачисления <div className="table-data__required" />
                  </div>
                  <div className="table-data__body">
                    <div className="table-data-grid-3">
                      <FormikDatePicker
                        size="small"
                        name="enrollDate"
                      />
                    </div>
                  </div>
                </div>
                <Document
                  name="enrollDocumentType"
                  label="Вид документа-основания"
                  editMode
                  formType={FormTypeEnum.EditRequestEnrollment}
                  required
                  size={3}
                />
                <div className="table-data__item table-data__group">
                  <div className="table-data__label table-data__label--main">
                    Номер и дата документа <div className="table-data__required" />
                  </div>
                  <div className="table-data__body">
                    <div className="table-data-grid-2">
                      <FormikInput
                        size="small"
                        name="enrollDocNumber"
                        placeholder="Введите номер..."
                      />
                      <FormikDatePicker
                        size="small"
                        name="enrollDocDate"
                      />
                    </div>
                  </div>
                </div>
                <SimpleTextArea
                  name="enrollComment"
                  editMode
                  label="Комментарий"
                  placeholder="Введите..."
                />
              </div>
            </div>
            <div className="requests-hr" />
            <div className="container">
              <div className="flex justify-end">
                <LmButton
                  type="button"
                  variant="outline"
                  size="medium"
                  onClick={() => resetForm()}
                >
                  Сбросить
                </LmButton>
                <Push
                  size={12}
                  orientation="horizontal"
                />
                <LmButton
                  type="button"
                  size="medium"
                  disabled={!isValid || isSubmitting}
                  onClick={() => setShowModal(true)}
                >
                  Зачислить
                </LmButton>
              </div>
              <Push size={16} />
            </div>
            <EnrollmentModal
              showModal={showModal}
              setShowModal={setShowModal}
              handleSubmit={handleSubmit}
            />
          </form>
        );
      }}
    </Formik>
  );
};

export default ActionsFormEnrollment;

const getValidationSchema = () =>
  objectYup().shape({
    enrollDate: docDate.nullable().required('Выберите дату зачисления'),
    enrollDocumentTypeId: documentTypeId,
    enrollDocNumber,
    enrollDocDate: docDate.nullable().required('Выберите дату документа'),
    serviceClass: objectYup().shape({
      id: serviceClassId,
    }),
  });
