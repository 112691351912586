import React, { useCallback } from 'react';
import contingentApi from '../../../../../lib/api/contingents';
import requestsApi from '../../../../../lib/api/requests';
import { typePersonEnum } from '../../../../../mock-data/type-person-enum';
import { LikeModalProps, ListPerson, Person } from '../../../../../types/like-modal';
import LikeModal from './components';

const ApplicantLikeModal = ({ show, onClose, extendEntityGuid, requestId, data, updateRequest }: LikeModalProps) => {
  const getListPerson = useCallback(async (): Promise<ListPerson[] | undefined> => {
    if (extendEntityGuid) {
      return await contingentApi.getApplicantLike(extendEntityGuid);
    }
  }, [extendEntityGuid]);

  const createNewPerson = async () => {
    if (requestId) {
      return await requestsApi.newPerson(requestId, typePersonEnum.applicant);
    }
  };

  const selectPerson = async (checked: Person) => await contingentApi.updateApplicant(extendEntityGuid, checked);

  return (
    <LikeModal
      title="Проверка сведений заявителя"
      open={show}
      close={onClose}
      data={data}
      requestId={requestId}
      updateRequest={updateRequest}
      createNewPerson={createNewPerson}
      getList={getListPerson}
      onSelectPerson={selectPerson}
    />
  );
};

export default ApplicantLikeModal;
