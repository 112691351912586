import React, { useCallback, useEffect, useState } from 'react';
import { object as objectYup } from 'yup';
import { Formik, FormikProps } from 'formik';
import { Push } from '@mosru/esz_uikit';
import { LmLoader } from '@mes-ui/lemma';
import useInitialErrors from '../../../hooks/formik-initial-errors';
import FormikDatePicker from '../../../components/formik/formik-datepicker';
import FormikInput from '../../../components/formik/formik-input';
import { PersonRequestData, RequestEnrollmentData } from '../../../types/requests';
import SimpleTextArea from '../../../components/fields/simple-textarea';
import Document from '../components/fields/document';
import { FormTypeEnum } from '../../../mock-data/form-type-enum';
import ShortNumberInfobox from '../components/short-number-infobox';
import { checkStatus, submitEnrollment } from '../utils';
import { EnrollmentModal } from '../components/modals';
import { docDate, documentTypeId, enrollDocNumber } from '../../../lib/utils/validation';
import { Statuses } from '../statuses';
import SavePanel from '../../../components/save-panel';
import { RequestStatusEnum } from '../../../mock-data/request-status-enum';

type Props = {
  enrollment: RequestEnrollmentData;
  editMode: boolean;
  setEditMode: () => void;
  requestId: number;
  updateRequest: () => void;
  changeStatusRequest?: () => void;
  contingentGuid: string;
  serviceClassId: number;
  child: PersonRequestData;
  requestStatusId: RequestStatusEnum;
};

const ActionsFormEnrollment: React.FC<Props> = ({
  enrollment,
  editMode,
  setEditMode,
  requestId,
  updateRequest,
  changeStatusRequest,
  contingentGuid,
  serviceClassId,
  child,
  requestStatusId,
}) => {
  const [loading, setLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);

  const [loadingCheck, setLoadingCheck] = useState<boolean>(false); // loading проверки расписаний
  const [intersection, setIntersection] = useState<boolean>(false); // пересечение дат

  const [message, setMessage] = useState<string>(''); // сообщение если найдены пересечения

  const initialErrors = useInitialErrors(enrollment, getValidationSchema);
  const submitForm = useCallback(
    async (values: RequestEnrollmentData) => {
      await submitEnrollment(values, requestId, setLoading, setShowModal, setEditMode, updateRequest);
      // При сохранении заявления в статусе "Черновик" переводим его в статус "Новое"
      changeStatusRequest && changeStatusRequest();
      updateRequest();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [requestId, updateRequest, setEditMode]
  );

  const updatedInitialValues = {
    ...enrollment,
    enrollDocumentTypeId: FormTypeEnum.EditRequestEnrollment,
  };

  useEffect(() => {
    checkStatus(serviceClassId, contingentGuid, setMessage, setLoadingCheck, setIntersection);
  }, [contingentGuid, serviceClassId]);

  return loading ? (
    <div className="loader-container">
      <LmLoader
        view="page"
        title="Идет сохранение! "
        description="Пожалуйста, подождите..."
      />
    </div>
  ) : (
    <Formik
      initialErrors={initialErrors}
      validationSchema={getValidationSchema}
      onSubmit={submitForm}
      enableReinitialize
      initialValues={updatedInitialValues}
    >
      {(formikProps: FormikProps<RequestEnrollmentData>) => {
        const { handleSubmit, isValid, isSubmitting, resetForm } = formikProps;

        return (
          <>
            <ShortNumberInfobox
              requestStatusId={requestStatusId}
              child={child}
            />

            <Statuses
              message={message}
              intersection={intersection}
              loadingCheck={loadingCheck}
              contingentGuid={contingentGuid}
            />

            <form onSubmit={handleSubmit}>
              <div className="container">
                <Push size={20} />
                <div className="table-data">
                  <div className="table-data__item table-data__group">
                    <div className="table-data__label table-data__label--main">
                      Дата зачисления <div className="table-data__required" />
                    </div>
                    <div className="table-data__body">
                      <div className="table-data-grid-3">
                        <FormikDatePicker
                          disabled={!editMode}
                          placeholder={editMode ? 'ДД.ММ.ГГГГ' : ''}
                          size="small"
                          name="enrollDate"
                        />
                      </div>
                    </div>
                  </div>
                  <Document
                    name="enrollDocumentType"
                    label="Вид документа-основания"
                    editMode
                    formType={FormTypeEnum.EditRequestEnrollment}
                    required
                    // optionsData={dictionaryDocType}
                    disabled={!editMode}
                    size={3}
                  />
                  <div className="table-data__item table-data__group">
                    <div className="table-data__label table-data__label--main">
                      Номер и дата документа <div className="table-data__required" />
                    </div>
                    <div className="table-data__body">
                      <div className="table-data-grid-2">
                        <FormikInput
                          disabled={!editMode}
                          size="small"
                          name="enrollDocNumber"
                          placeholder={editMode ? 'Введите номер...' : ''}
                        />
                        <FormikDatePicker
                          disabled={!editMode}
                          size="small"
                          name="enrollDocDate"
                          placeholder={editMode ? 'ДД.ММ.ГГГГ' : ''}
                        />
                      </div>
                    </div>
                  </div>
                  <SimpleTextArea
                    name="enrollComment"
                    disabled={!editMode}
                    editMode={editMode}
                    label="Комментарий"
                    placeholder={editMode ? 'Введите...' : ''}
                  />
                </div>
              </div>

              {editMode && (
                <SavePanel
                  buttonPrimaryText="Зачислить"
                  primaryButtonModifiers={{
                    loading: isSubmitting,
                    disabled: !isValid,
                  }}
                  onClickPrimaryButton={() => setShowModal(true)}
                  onClickSeconadaryButton={() => {
                    resetForm();
                    setEditMode();
                  }}
                />
              )}
              <EnrollmentModal
                showModal={showModal}
                setShowModal={setShowModal}
                handleSubmit={handleSubmit}
              />
            </form>
          </>
        );
      }}
    </Formik>
  );
};

export default ActionsFormEnrollment;

const getValidationSchema = objectYup().shape({
  enrollDate: docDate.nullable().required('Выберите дату зачисления'),
  enrollDocumentTypeId: documentTypeId,
  enrollDocNumber,
  enrollDocDate: docDate.nullable().required('Выберите дату документа'),
});
