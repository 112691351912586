import { LmInfoBox } from '@mes-ui/lemma';
import React from 'react';
import { numberBirthRecordRegExp } from '../../../lib/utils/validation';
import { DocumentTypeEnum } from '../../../mock-data/type-document';
import { PersonRequestData } from '../../../types/requests';
import { RequestStatusEnum } from '../../../mock-data/request-status-enum';

type ShortNumberInfoboxProps = {
  child: PersonRequestData | undefined;
  requestStatusId: number | undefined;
};
const ShortNumberInfobox = ({ child, requestStatusId }: ShortNumberInfoboxProps) => {
  const isShortBirthRecord =
    !!child &&
    child.documentTypeId === DocumentTypeEnum.BirthRecord &&
    !!child.number &&
    !numberBirthRecordRegExp.test(child.number.toString()) &&
    !!requestStatusId &&
    requestStatusId !== RequestStatusEnum.ForInclude;

  return isShortBirthRecord ? (
    <div className="infobox__container">
      <LmInfoBox
        dataTest="shortNumberAlert"
        variant="alert"
        className="infobox--full-width"
        description="Зачисление обучающегося с данным номером записи о рождении в ЕГР ЗАГС невозможно. Выполните отказ в зачислении и сообщите заявителю о необходимости подачи заявления по свидетельству о рождении или с 21-разрядным номером записи о рождении в ЕГР ЗАГС"
        hidenFooter
      />
    </div>
  ) : null;
};

export default ShortNumberInfobox;
